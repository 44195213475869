<template>
  <div>
    <b-row>
      <b-col lg="6" md="6" sm="6" class="heading-title">
        <h4>Pincode Management</h4>
        <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
          <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
          <b-breadcrumb-item active>Pincode Management</b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
      <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
    </b-row>
    <br />
    <b-card class="mb-4 w-100">
       <div class="venue-heading">
        <b-row>
        
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="12"
            md="12"
            sm="12"
          >
            <div class="w-100 d-flex justify-content-end">
               <div class="p-2">
                 <b-button
                variant="primary"
                v-b-modal.ImportPincodeModal
                ><i class="mdi mdi-plus"></i> Import</b-button
              >
              </div>
              <div class="p-2">
                <b-button variant="primary" @click="applyFilter()">
                  Apply</b-button
                >
              </div>
              <div class="p-2">
                <b-button variant="primary" @click="resetFilter()">
                  Reset</b-button
                >
              </div>
              <div class="p-2">
                <b-button
                  id="button_width"
                  @click="export_pincode()"
                  variant="primary"
                >
                  <i class="mdi mdi-export"></i>Export</b-button
                >
              </div>
              <div class="p-2">
                <b-button
                  id="button_width1"
                  variant="primary"
                  @click="CreateParentVanue()"
                >
                  <i class="mdi mdi-plus"></i>Parent Venue</b-button
                >
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
       <div class="venue-heading">
        <b-row>
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
             <multiselect
                v-model="region"
                :options="region_option"
                placeholder="Select Region"
              >
              </multiselect>
            </div>
          </b-col>
          <b-col
            class="d-flex align-items-stretch "
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
              <div>
                 <multiselect
                v-model="district"
                :options="district_option"
                placeholder="Select District"
              >
              </multiselect>
              </div>
            </div>
          </b-col>
         <b-col
            class="d-flex align-items-stretch "
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
              <div>
                 <multiselect
                v-model="pincode"
                :options="pincode_option"
                placeholder="Select Pincode"
              >
              </multiselect>
              </div>
            </div>
          </b-col>
           <b-col
            class="d-flex align-items-stretch "
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
              <div>
                <multiselect
                v-model="price"
                :options="price_option"
                placeholder="Select Price"
              >
              </multiselect>
              </div>
            </div>
          </b-col>
           <b-col
            class="d-flex align-items-stretch "
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
              <div>
                 <multiselect
                v-model="status"
                :options="pincode_status"
                placeholder="Select Status"
              >
              </multiselect>
              </div>
            </div>
          </b-col>
            <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
               <multiselect
                v-model="state"
                :options="state_option"
                placeholder="Select State"
              >
              </multiselect>
            </div>
          </b-col>
          <b-col
            class="d-flex align-items-stretch "
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
              <div>
                 <multiselect
                v-model="division"
                :options="division_option"
                placeholder="Select Division"
              >
              </multiselect>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
       <div>
        <b-row> 
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="4"
            md="6"
            sm="6"
          >
            <div
              class="
                w-100
                justify-content-sm-center
                justify-content-xl-start
                justify-content-md-start
              "
            >
              <div class="p-2">
                <b-form-select
                id="select_perpage"
                v-model="pageSize"
                :options="options"
                @change="handlePageSizeChange($event)"
              ></b-form-select>
              </div>
            </div>
          </b-col>
          
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="8"
            md="12"
            sm="12"
          >
            <div class="w-100 p-2">
               <b-form-input
                v-model="search"
                id="search"
                type="text"
                placeholder="Search here"
                v-on:keyup="key_search()"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
      </div>
      <br />
      <div>
        <b-table
          :fields="fields"
          :items="pincodes"
          responsive
          hover
          bordered
          show-empty
          empty-text="No Data Available"
          class="text-center"
        >
          <template #cell(price)="data">
            <b-button variant="primary" v-if="data.item.price !== null" size="sm">
            <span v-on:click="editPrice(data)" v-b-tooltip.hover title="Click on price to update price"> {{ data.item.price }}</span>
            </b-button>
          </template>
        </b-table>
        <b-row align-v="center">
          <b-col cols="4">
            <span
              >Showing <b>{{ totalRows }}</b> out of
              <b>{{ totalData }}</b> entries.</span
            >
          </b-col>
          <b-col cols="8">
            <b-pagination
              v-model="page"
              :total-rows="count"
              :per-page="pageSize"
              prev-text="Prev"
              next-text="Next"
              align="right"
              @change="handlePageChange"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      :no-close-on-backdrop="true"
      id="ImportPincodeModal"
      hide-header
      size="md"
      @ok="getImportData"
      ok-title="Import"
      @cancel="closeFile"
    >
      <center>
        <h4><b>Import Your Pincode File</b></h4>
      </center>
      <b-form enctype="multipart-formdata">
        <b-row>
          <b-col cols="12">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="import_file"
                  >Attachment :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-file
                  type="file"
                  name="import_file"
                  :state="validateFileState('import_file')"
                  v-model="$v.importFile.import_file.$model"
                  accept=".csv, .xls, .xlsx"
                ></b-form-file>
                <b-form-invalid-feedback id="import_file-1-live-feedback"
                  ><span v-if="!this.$v.importFile.import_file.required">
                    Please select file to import pincodes.</span
                  ></b-form-invalid-feedback
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      :no-close-on-backdrop="true"
      id="UpdatePriceModal"
      @ok="handleOk"
      @cancel="close"
      hide-header
      size="md"
      ok-title="Update"
    >
      <center>
        <h4><b>Update Price</b></h4>
      </center>
      <b-form enctype="multipart-formdata">
        <b-row>
          <b-col cols="12">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="fname"
                  >State :</label
                ></b-col
              >
              <b-col cols="7">
                <span id="h_value">{{ update_state }}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="fname"
                  >Division :</label
                ></b-col
              >
              <b-col cols="7">
                <span id="h_value">{{ update_division }}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="fname"
                  >Region :</label
                ></b-col
              >
              <b-col cols="7">
                <span id="h_value">{{ update_region }}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="fname"
                  >District :</label
                ></b-col
              >
              <b-col cols="7">
                <span id="h_value">{{ update_district }}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="fname"
                  >Pincode :</label
                ></b-col
              >
              <b-col cols="7">
                <span id="h_value">{{ update_pincode }}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="fname"
                  >Price :</label
                ></b-col
              >
              <b-col cols="7">
                <span id="h_value">{{ update_price }}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="fname"
                  >New Price :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="price"
                  type="number"
                  class="no-arrow"
                  :state="validateState('price')"
                  v-model="updatePriceForm.price"
                  placeholder="Enter New Price"
                  aria-describedby="price-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="price-1-live-feedback">
                  <span v-if="!this.$v.updatePriceForm.price.required">
                    Please Enter New Price.</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
export default {
  name: "managepincode",
  mixins: [validationMixin],
  data: () => ({
    title: "managepincode",
    updatePriceForm: {
      price: "",
    },
    importFile: {
      import_file: [],
    },
    fields: [
      {
        key: "state",
        label: "State",
        sortable: true,
      },
      {
        key: "division",
        label: "Division",
        sortable: true,
      },
      {
        key: "region",
        label: "Region",
        sortable: true,
      },
      {
        key: "district",
        label: "District",
        sortable: true,
      },
      {
        key: "pincode",
        label: "Pincode",
        sortable: true,
      },
      {
        key: "price",
        label: "Price",
        sortable: true,
      },
      {
        key: "status",
        label: "Status",
        sortable: false,
      },
    ],
    item: [],
    state: "",
    pincode: "",
    district: "",
    division: "",
    region: "",
    price: "",
    status: "",
    pincode_id: "",
    update_state: "",
    update_pincode: "",
    update_district: "",
    update_division: "",
    update_region: "",
    update_price: "",
    venueCounts: 0,
    pincodes: [],
    options: [10, 20, 30, 50],
    pincode_status: ["Not Allocated", "Allocated"],

    pageSize: 10,
    show: false,
    load: true,
    filter: "",
    search: "",
    CurrentPage: 1,
    sortBy: "created_at",

    page: 1,
    count: 0,
    totalData: "",
    totalRows: "",

    PerPage: "50",

    state_option: [],
    division_option: [],
    region_option: [],
    district_option: [],
    pincode_option: [],
    price_option: [],
    status_option: [],
  }),
  validations: {
    updatePriceForm: {
      price: { required, numeric },
    },
    importFile: {
      import_file: { required },
    },
  },
  components: { Multiselect },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.updatePriceForm[name];
      return $dirty ? !$error : null;
    },

    validateFileState(import_file) {
      const { $dirty, $error } = this.$v.importFile[import_file];
      return $dirty ? !$error : null;
    },

    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$v.updatePriceForm.$touch();
      if (this.$v.updatePriceForm.$anyError) {
        return;
      } else {
        this.updatePrice();
      }
    },

    close() {
      this.$nextTick(() => {
        this.$bvModal.hide("UpdatePriceModal");
        this.updatePriceForm = {
          price: "",
        };
        this.$v.$reset();
      });
    },

    updatePrice() {
      var pincode_id = this.pincode_id;
      this.$v.updatePriceForm.$touch();
      if (!this.$v.updatePriceForm.$anyError) {
        axios
          .post("/admin/update_pincode_price", {
            pincode_id: pincode_id,
            price: this.updatePriceForm.price,
          })
          .then((resp) => {
            if (resp.data.status) {
              this.close();
              this.pincodeListing();
              this.managePincodeListing();
              this.$root.$refs.app.showToast("success", resp.data.message);
            } else {
              this.$root.$refs.app.showToast("danger", resp.data.message);
            }
          });
      }
    },

    handlePageChange(value) {
      this.page = value;
      this.load = true;
      this.pincodeListing();
    },
    handlePageSizeChange(event) {
      this.pageSize = event;
      this.load = true;
      this.page = 1;
      this.pincodeListing();
    },
    sortChanged(e) {
      this.sortBy = e.sortBy;
      this.load = true;
      this.pincodeListing();
    },
    pincodeListing() {
      this.load = true;
      var keyword = this.search;
      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.sortBy
      );
      axios
        .post("/admin/pincodes_fetch", {
          params,
          search_keyword: keyword,
          state: this.state,
          district: this.district,
          region: this.region,
          division: this.division,
          pincode: this.pincode,
          price: this.price,
          status: this.status,
        })
        .then((resp) => {
          if (resp.data.status_code == 200) {
            this.pincodes = [];
            if (resp.data.status) {
              this.count = this.totalData = resp.data.total_records;
              this.totalRows = resp.data.pincodes.length;

              for (var i = 0; i < resp.data.pincodes.length; i++) {
                if (resp.data.pincodes[i].status == "0") {
                  resp.data.pincodes[i].status = "Not Allocated";
                } else {
                  resp.data.pincodes[i].status = "Allocated";
                }
                this.pincodes.push({
                  id: resp.data.pincodes[i].id,
                  state: resp.data.pincodes[i].state,
                  division: resp.data.pincodes[i].division,
                  region: resp.data.pincodes[i].region,
                  district: resp.data.pincodes[i].district,
                  pincode: resp.data.pincodes[i].pincode,
                  price: resp.data.pincodes[i].price,
                  status: resp.data.pincodes[i].status,
                });
              }
              this.show = false;
              this.load = false;
            } else {
              this.count = this.totalData = resp.data.total_records;
              this.totalRows = resp.data.pincodes.length;

              this.show = false;
              this.load = false;
            }
          }
        });
    },
    getRequestParams(page, pageSize, sortBy = "state") {
      let params = {};
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      params["sortBy"] = sortBy;
      return params;
    },
    managePincodeListing() {
      axios.get("/admin/manage_pincode_listing").then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            this.state_option = resp.data.states;
            this.division_option = resp.data.divisions;
            this.region_option = resp.data.regions;
            this.district_option = resp.data.districts;
            this.pincode_option = resp.data.pincodes;
            this.price_option = resp.data.prices;
          }
        }
      });
    },
    applyFilter() {
      const value = "1";
      this.handlePageChange(value);
    },
    resetFilter() {
      this.state = "";
      this.pincode = "";
      this.district = "";
      this.division = "";
      this.region = "";
      this.price = "";
      this.status = "";
      this.search = "";
      this.pincodeListing();
    },
    key_search() {
      const value = "1";
      this.handlePageChange(value);
    },
    export_pincode() {
      var keyword = this.search;
      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.sortBy
      );
      axios
        .post(
          "/admin/export_pincode_pdf",
          {
            params,
            search_keyword: keyword,
            state: this.state,
            district: this.district,
            region: this.region,
            division: this.division,
            pincode: this.pincode,
            price: this.price,
            status: this.status,
          },
          {
            responseType: "blob",
          }
        )
        .then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "pincode_list.pdf");
          document.body.appendChild(link);
          link.click();
        });
    },

    editPrice(data) {
      this.$root.$emit("bv::show::modal", "UpdatePriceModal");
      var pincode_id = data.item.id;
      axios
        .post("/admin/edit_pincode_price", {
          pincode_id: pincode_id,
        })
        .then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              this.pincode_id = resp.data.pincode.id;
              this.update_state = resp.data.pincode.state;
              this.update_division = resp.data.pincode.division;
              this.update_region = resp.data.pincode.region;
              this.update_district = resp.data.pincode.district;
              this.update_pincode = resp.data.pincode.pincode;
              this.update_price = resp.data.pincode.price;
            }
          }
        });
    },
    getImportData(e) {
      e.preventDefault();
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.$v.importFile.$touch();
      if (this.$v.importFile.$anyError) {
        return;
      } else {
        let data = new FormData();
        data.append("import_file", this.importFile.import_file);
        axios.post("/admin/import_pincodes", data, config).then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status == true) {
              this.$root.$refs.app.showToast("success", resp.data.message);
              this.close();
              this.managePincodeListing();
              this.pincodeListing();
              this.$bvModal.hide("ImportPincodeModal");
            } else {
              this.$root.$refs.app.showToast("danger", resp.data.message);
            }
          }
        });
      }
    },

    closeFile() {
      this.$nextTick(() => {
        this.$bvModal.hide("ImportPincodeModal");
        this.importFile = {
          import_file: [],
        };
        this.$v.$reset();
      });
    },
  },
  mounted() {
    this.pincodeListing();
    this.managePincodeListing();
    this.show = true;
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.no-arrow {
  -moz-appearance: textfield;
}
.no-arrow::-webkit-inner-spin-button {
  display: none;
}
.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>